@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-navbar: #ffd100;
  --color-navbarHover: #ffc600;
  --color-hover: #456990;
  --color-primary: #314359;
  --color-secondary: #ffd100;
  --color-success: #7dcd85;
  --color-error: #ee4266;

  @apply text-[62.5%];
  @apply lg:text-[56.25%];
  @apply md:text-[50%];
}

#root .react-grid-item.react-grid-placeholder {
  background: transparent;
  border: 2px dashed black;
  @apply rounded-4;
}

.leaflet-control-container > div {
  z-index: 450;
}

div[type='button'] {
  appearance: none;
  -webkit-appearance: none;
}

input {
  font: inherit;
}
